.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker-wrapper {
  //display: block;
  padding: 0;
  border : 0;
  width  : 100%;
}

.react-datepicker {
  font-family     : $datepicker__font-family;
  font-size       : $datepicker__font-size;
  background-color: $datepicker__background-color;
  color           : $datepicker__text-color;
  border          : 1px solid $datepicker__border-color;
  border-radius   : $datepicker__border-radius;
  display         : inline-block;
  position        : relative;
}

.react-datepicker__header {
  text-align            : center;
  background-color      : $datepicker__background-color;
  border-bottom         : 1px solid $datepicker__border-color;
  border-top-left-radius: $datepicker__border-radius;
  padding               : 8px 0;
  position              : relative;

  &--time {
    padding-bottom: 8px;
    padding-left  : 5px;
    padding-right : 5px;

    &:not(&--only) {
      border-top-left-radius: 0;
    }
  }

  &:not(&--has-time-select) {
    border-top-right-radius: $datepicker__border-radius;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top : 0;
  color      : $datepicker__header-color;
  font-weight: bold;
  font-size  : $datepicker__font-size * 1.18;
}

.react-datepicker__time-container {
  float      : right;
  border-left: 1px solid $datepicker__border-color;
  width      : 85px;

  &--with-today-button {
    display      : inline;
    border       : 1px solid $datepicker__border-color;
    border-radius: 0.3rem;
    position     : absolute;
    right        : -72px;
    top          : 0;
  }

  .react-datepicker__time {
    position                  : relative;
    background                : $datepicker__background-color;
    border-bottom-right-radius: 0.3rem;

    .react-datepicker__time-box {
      width                     : 85px;
      overflow-x                : hidden;
      margin                    : 0 auto;
      text-align                : center;
      border-bottom-right-radius: 0.3rem;

      ul.react-datepicker__time-list {
        list-style   : none;
        margin       : 0;
        height       : calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y   : scroll;
        padding-right: 0;
        padding-left : 0;
        width        : 100%;
        box-sizing   : content-box;

        li.react-datepicker__time-list-item {
          height     : 30px;
          padding    : 5px 10px;
          white-space: nowrap;

          &:hover {
            cursor          : pointer;
            background-color: $datepicker__background-color;
          }

          &--selected {
            background-color: $datepicker__selected-color;
            color           : $black;
            font-weight     : normal;

            &:hover {
              background-color: $datepicker__selected-color;
            }
          }

          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor          : default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color      : $datepicker__muted-color;
  display    : inline-block;
  width      : $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align : center;
  margin     : $datepicker__day-margin;

  &.react-datepicker__week-number--clickable {
    cursor: pointer;

    &:hover {
      border-radius   : $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color      : $datepicker__text-color;
  display    : inline-block;
  width      : $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align : center;
  margin     : $datepicker__day-margin;

  &--weekend {
    background-color: theme-color(info);
  }
}

.react-datepicker__month,
.react-datepicker__quarter {

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius   : $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color           : $black;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--disabled {
    color         : $datepicker__muted-color;
    pointer-events: none;

    &:hover {
      cursor          : default;
      background-color: transparent;
    }
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    border-radius   : $datepicker__border-radius;
    background-color: lighten($datepicker__background-color, 15%);
  }

  &--today {
    font-weight: bolder;
    color      : $datepicker__highlighted-color;
  }

  &--highlighted {
    border-radius   : $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color           : #fff;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: theme-color(info);
    }

    &-custom-2 {
      color: theme-color(success);
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius   : $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color           : $black;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }


  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color           : $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color : $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {

  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: $datepicker__selected-color;
    }
  }

  &:hover {
    background-color: $datepicker__background-color;
  }
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border       : 1px solid transparent;
  border-radius: $datepicker__border-radius;
  position     : relative;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position        : absolute;
  width           : 50%;
  left            : 25%;
  top             : 30px;
  z-index         : 1;
  text-align      : center;
  border-radius   : $datepicker__border-radius;
  border          : 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height    : 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height : 20px;
  width       : 100%;
  display     : block;
  margin-left : auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius : $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select       : none;
    -moz-user-select          : none;
    -ms-user-select           : none;
    user-select               : none;
    border-bottom-left-radius : $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left    : 15px;
  }
}

.react-datepicker__close-icon {
  cursor          : pointer;
  background-color: transparent;
  border          : 0;
  outline         : 0;
  padding         : 0 6px 0 0;
  position        : absolute;
  top             : 0;
  right           : 0;
  height          : 100%;
  display         : table-cell;
  vertical-align  : middle;

  &::after {
    cursor          : pointer;
    background-color: $datepicker__selected-color;
    color           : $black;
    border-radius   : 50%;
    height          : 16px;
    width           : 16px;
    padding         : 2px;
    font-size       : 12px;
    line-height     : 1;
    text-align      : center;
    display         : table-cell;
    vertical-align  : middle;
    content         : "\00d7";
  }
}

.react-datepicker__today-button {
  background : $datepicker__background-color;
  border-top : 1px solid $datepicker__border-color;
  cursor     : pointer;
  text-align : center;
  font-weight: bold;
  padding    : 5px 0;
  clear      : left;
}

.react-datepicker__portal {
  position        : fixed;
  width           : 100vw;
  height          : 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left            : 0;
  top             : 0;
  justify-content : center;
  align-items     : center;
  display         : flex;
  z-index         : 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width      : 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px),
  (max-height: 550px) {

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width      : 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.8;
  }
}

.daterange-picker {
  display: flex;

  @media (max-width: 576px) {
    display: block;
  }

  .react-datepicker-wrapper {
    flex-grow: 1;

    @media (max-width: 576px) {
      width: 100%
    }
  }

  .range-seperator {
    margin: auto 2rem;

    @media (max-width: 576px) {
      width     : 40px;
      display   : block;
      text-align: center;
      margin    : 1rem auto;
    }
  }
}